import { Button, Dialog, DialogContent, Divider, LinearProgress } from "@mui/material"
import CloseButton from "./common/CloseButton"
import VoteIcon from '@mui/icons-material/ThumbUp'
import React, { useEffect, useState } from "react"
import { checkVotes, VoteRewardResponse } from "../service/userService"
import useAppContext from "../context/useAppContext"
import CoinIcon from '@mui/icons-material/MonetizationOn'
import { SUPPORT_URL } from "../config/urls"
import ReviewsIcon from '@mui/icons-material/Reviews'

interface VoteBotModalProps {
  open: boolean
  setOpen?: (open: boolean) => void
}

export const VoteBotModal = ({ open, setOpen }: VoteBotModalProps) => {

  const [loading, setLoading] = useState(false)
  const [clickedLeaveReview, setClickedLeaveReview] = useState(false)
  const { user: userData, guild, faqs, guildAnalytics } = useAppContext()

  const onClose = () => {
    setLastVotePrompt().then()
    setOpen && setOpen(false)
  }

  const suggestLeavingReview = (faqs?.length >= 3 || (guildAnalytics?.global?.total_answers || 0) >= 3) && Math.random() < 0.4

  if (!userData || !guild) {
    return null
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={(e, r) => {
        if (r !== "backdropClick") {
          onClose()
        }
      }}
      fullWidth
      BackdropProps={{
        classes: {
          root: "blur-sm bg-[#00001e] backdrop-blur-sm opacity-40",
        },
      }}
    >
      {setOpen && (
        <div className="ml-auto">
          <CloseButton onClick={onClose}/>
        </div>
      )}
      <DialogContent>
        {suggestLeavingReview && (
          <>
            <div>
              <div className="text-2xl font-bold text-center flex gap-2 items-center justify-center">
                <CoinIcon/>
                <span className="underline">Leave a review for free credits</span>
                <CoinIcon/>
              </div>
              <div className="text-center my-6">
                <span className="text-xl">
            Leave a review and receive <span className="text-green-500 font-bold">150 000</span> free credits!
                </span>
                <br/>
                Write how you're using Wallu and what you like about it.
              </div>
              <div className="mt-6 w-2/3 mx-auto text-center">
                <div className="">
                  <Button href="https://top.gg/bot/973282202894872696#reviews" target="_blank" rel="noreferrer"
                    className="mt-4" variant="contained" color="success"
                    onClick={() => setClickedLeaveReview(true)}
                    startIcon={<ReviewsIcon/>}
                  >
                    Leave a review
                  </Button>
                </div>
                {clickedLeaveReview && (
                  <div>
                    Did you leave a review?
                    Message us on the <a href={SUPPORT_URL} className="text-blue-400" target="_blank" rel="noreferrer"> Discord Server</a> to receive your
                    credits!
                  </div>
                )}
              </div>
            </div>

            <div className="my-6">
              <Divider/>
            </div>
          </>
        )}

        <div className="text-2xl font-bold text-center flex gap-2 items-center justify-center">
          <CoinIcon/>
          <span className="underline">Vote for free credits</span>
          <CoinIcon/>
        </div>
        <div className="text-center my-6">
          <span className="text-xl">
            Vote daily for <span className="text-green-500 font-bold">5000</span> free credits!
          </span>
          <br/>
          It will take less than a minute to vote Wallu on top.gg
        </div>
        <div className="mt-6 w-2/3 mx-auto text-center">
          <div className="">
            <Button href="https://top.gg/bot/973282202894872696/vote" target="_blank" rel="noreferrer"
              className="mt-4" variant="contained" color="success"
              onClick={() => setLoading(true)}
              startIcon={<VoteIcon/>}
            >
              Vote
            </Button>
          </div>
        </div>
        <div className="mt-6">
          <VotedModal loading={loading} guildId={guild.id} userId={userData.user.id}/>
        </div>
      </DialogContent>
    </Dialog>
  )
}


const VotedModal = ({ loading, guildId, userId }: any) => {

  const [data, setData] = useState<VoteRewardResponse | undefined>(undefined)

  // poll every X seconds
  useEffect(() => {
    let counter = 0
    const interval = setInterval(() => {
      counter++
      if (counter > 2 && counter < 20) {
        // @ts-ignore
        checkVotes(guildId, userId).then(data => {
          if (data.new_votes > 0) {
            setData(data)
          }
        })
      }
      if (counter === 20 && !data) {
        window.alert("No vote detected :( Either you didn't vote or there was an error.")
      }
    }, 5_000)
    return () => clearInterval(interval)
  }, [guildId, userId])

  const showLoading = loading && data === undefined

  const dateFormatOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  return (
    <div>
      {data && (
        <div>
          <div className="text-2xl font-bold text-center">Thanks for voting!</div>
          <div className="text-center my-6">
            You have received <span className="text-green-500 font-bold">{data.new_credits}</span> credits from voting today and total
            of <span
              className="text-green-500 font-bold">{data.total_credits_from_voting}</span> credits from current vote streak.
            <br/>
            Vote again before <b>{new Date(data.streak_expires_at).toLocaleDateString(undefined, dateFormatOptions)}</b> to keep your
            streak!
            Otherwise, your credits will expire <b>{new Date(data.credits_expires_at).toLocaleDateString(undefined, dateFormatOptions)}</b>.
            <br/>
            You can vote again in about 12 hours.
          </div>
        </div>
      )}
      <div className="w-3/4 m-auto mt-4">
        {showLoading && (
          <>
            <LinearProgress/>
            <p className="text-center text-gray-400 text-sm mt-2">
              Checking if you voted...
            </p>
          </>
        )}
      </div>
    </div>
  )
}


// Good enough for now
export function getLastVotePrompt() {
  return window.localStorage.getItem("last-vote-prompt")
}

async function setLastVotePrompt() {
  window.localStorage.setItem("last-vote-prompt", new Date().toISOString())
}

export function canVoteAgain() {
  const lastVoted = getLastVotePrompt()
  // assume we can vote 12,1h after last voted, though it actually is 12
  // we can make it better in the future
  return lastVoted ? new Date(lastVoted).getTime() + (12.1 * 60 * 60 * 1000) < new Date().getTime() : true
}
