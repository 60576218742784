import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid'
import { ImportedFAQ } from "../service/faqService"
import { Button, Dialog, DialogContent } from '@mui/material'
import CloseButton from "./common/CloseButton"
import useAppContext from "../context/useAppContext"
import { useNavigate } from "react-router-dom"
import faqLimitPopup from './common/FAQLimitPopup'

const CheckMarkValue = ({ value }: any) => (value ? (
  <span className="text-green-500 m-auto text-2xl" title="A similar FAQ already exists">✓</span>
) : null)

const columns: GridColDef[] = [
  {
    field: 'question', headerName: 'Question', editable: true, width: 500,
  },
  {
    field: 'answer', headerName: 'Answer', editable: true, width: 475,
  },
  {
    field: 'can_already_answer', headerName: 'FAQ exists', editable: false, width: 100, renderCell: CheckMarkValue,
  },
]

interface FaqTableImporterViewProps {
  open: boolean
  setOpen: (open: boolean) => void
  faqs: ImportedFAQ[]
  importFAQs: (faqs: ImportedFAQ[]) => void
}

const FaqTableImporterView = ({ open, setOpen, faqs, importFAQs }: FaqTableImporterViewProps) => {

  const context = useAppContext()
  const apiRef = useGridApiRef()
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows] = useState<ImportedFAQ[]>([])

  useEffect(() => {
    setSelectedRows(faqs.filter(faq => !faq.can_already_answer))
  }, [faqs])

  // Update every 2 seconds because without premium plan we cannot listen to all events
  useEffect(() => {
    const interval = setInterval(() => {
      const currentRows = [...(apiRef?.current?.getSelectedRows?.()?.values?.() || [])].map(row => row as ImportedFAQ)
      setSelectedRows(currentRows)
    }, 2_000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      PaperProps={{ className: "max-h-[90vh] min-h-[75vh]" }}
      open={open}
      onClose={(e, r) => {
        if (r !== "backdropClick") {
          setOpen(false)
        }
      }}
    >
      <div className="ml-auto">
        <CloseButton onClick={() => setOpen(false)}/>
      </div>
      <DialogContent>
        <div className="text-center my-10">
          <h2 className="text-2xl font-bold">Import FAQs</h2>
          <p className="text-sm text-gray-300 my-4">
            We have extracted {faqs.length} FAQs from your content.
            <br/>
            Select the FAQs you want to import and click the "Import" button.
            <br/>
            You can also edit the questions and answers before importing by clicking on the cells.
          </p>
        </div>

        <div className="h-[500px]">
          <DataGrid
            apiRef={apiRef}
            rows={faqs}
            columns={columns}
            checkboxSelection
            rowSelectionModel={selectedRows.map(faq => faq.id)}
            onRowSelectionModelChange={ids => {
              setSelectedRows(ids.map(id => apiRef?.current?.getRow?.(id)).filter(Boolean))
            }}
          />

          <div className="my-4 text-center">
            <Button
              disabled={selectedRows.length === 0}
              variant="contained"
              color="primary"
              onClick={async () => {
                if ((context.faqs.length + selectedRows.length) > (context.plan?.limits?.faqs || 100)) {
                  faqLimitPopup(context.plan!!)
                } else if (window.confirm(`Are you sure you want to import ${selectedRows.length} FAQs?`)) {
                  try {
                    setOpen(false)
                    await importFAQs(selectedRows)
                  } catch (err) {
                    console.error(err)
                    window.alert("Error occurred: " + err)
                    setOpen(true)
                  } finally {
                    navigate("/") // nooby but works hehe
                  }
                }
              }}
            >
              {selectedRows.length ? `Import ${selectedRows.length} FAQs` : "Import FAQs"}
            </Button>
          </div>

        </div>
      </DialogContent>
    </Dialog>
  )
}

export default FaqTableImporterView
