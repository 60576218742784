import { Box, LinearProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { TaskState } from "../../service/faqService"

interface TaskProgressProps {
  current: number;
  total: number;
  status: TaskState
  error?: string
  message?: string
}

export default function TaskProgress(props: TaskProgressProps) {

  const { current, total, status, error } = props

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(current / total * 100)
  }, [current, total])

  const statusText = {
    'PENDING': 'Queued...',
    'STARTED': 'Processing...',
    'PROGRESS': 'Processing...',
    'SUCCESS': 'Page(s) imported! Please wait...',
    'FAILURE': 'Task failed!',
    'REVOKED': 'Revoked!',
    'RETRY': 'Retrying...',
  }?.[status] || status

  return (
    <Box className="flex items-center justify-center flex-col">
      <Typography variant="body1">{statusText}</Typography>
      {!error && (
        <div className="min-w-[250px]">
          {props.message && (
            <p className="text-center text-gray-400">
              {props.message}
            </p>
          )}
          <div className="m-auto w-1/2 h-[10px] border border-gray-300 rounded">
            <LinearProgress variant="determinate" value={progress}/>
          </div>
        </div>
      )}
      {error && (
        <Typography variant="body1" color="error">{error}</Typography>
      )}
      <Typography variant="body2">{progress >= 0 ? `${(Math.max(progress - 1, 1)).toFixed(0)}%` : "unkown %"}</Typography>
    </Box>
  )
}
