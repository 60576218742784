import useRequest from "../../util/hooks/useRequest"
import { getProfile } from "../../service/userService"
import { Autocomplete, FormControl, LinearProgress, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { BASE_URL } from "../../config/axios"
import useAppContext from "../../context/useAppContext"

interface GuildSelectorProps {
  initialGuild: string | undefined
}

export default function GuildSelector({ initialGuild }: GuildSelectorProps) {

  const { guild, setGuild, setUser } = useAppContext()

  // @ts-ignore
  const { loading, data, error } = useRequest(getProfile, data => data.guilds.retry_after)
  const loadingOptions = { id: "0", name: loading ? "Loading..." : "No server selected" }
  const options = [...(data?.guilds || []), loadingOptions]
    .sort((x, y) => Number(y?.is_wallu_access) - Number(x?.is_wallu_access))

  useEffect(() => {
    if (data?.user) {
      setUser(data)
    }
    if (!guild) {
      if (initialGuild) {
        const found = options.find(it => it.id === initialGuild)
        if (found) {
          // @ts-ignore
          setGuild(found)
        }
      }
    }
  }, [data])

  if (error?.response && [401, 403].includes(error.response.status)) {
    window.location.href = `${BASE_URL}/auth?redirect_url=${encodeURIComponent(window.location.href)}`
  }

  return (
    <div className="flex items-center p-4 bg-[#1B1F27] rounded-lg shadow-lg">
      {guild?.icon_url ? <img src={guild.icon_url} loading="lazy" alt="" className="w-8 h-8 rounded-full inline-block mr-2"/> : null}
      <div className="w-full">
        <FormControl fullWidth>
          {loading && <LinearProgress className="bg-blue-900"/>}
          <Autocomplete
            value={(guild?.id ? guild : null) || loadingOptions}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <li {...props}>
                {option.icon_url ? <img src={option.icon_url} loading="lazy" alt="" className="w-8 h-8 rounded-full inline-block mr-2"/> : null}
                {option.name}
              </li>
            )}
            onChange={(e, value) => {
              if (value) {
                // @ts-ignore
                setGuild(value)
              }
            }}
            options={options}
            loading={loading}
            noOptionsText="Not found..."
            autoHighlight
            getOptionDisabled={g => !g.is_wallu_access}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                className="text-gray-200"
              />
            )}
          />
        </FormControl>
        {error && (
          <div className="mt-4">
            <div className="p-3 bg-red-900/30 border border-red-800 rounded text-red-400">
              {error.response?.data?.message || (
                <>
                  {error?.message}
                  <br/>
                  Wallu may be down (or restarting), please try again later.
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
