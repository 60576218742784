import React, { lazy, Suspense, useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import GuildSelector from "./components/partials/GuildSelector"
import { Divider, LinearProgress, Paper, Typography } from "@mui/material"
import AppContext, { AppContextInterface, defaultAppContext } from "./context/AppContext"
import useRequest from "./util/hooks/useRequest"
import { getServerData, ServerDataResponse } from "./service/faqService"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import useSearchParams from "./util/hooks/useSearchParams"
import AccountMenu from "./components/AccountMenu"
import { useViewport } from "./util/hooks/useViewport"
import { UsefulLinks } from "./components/UsefulLinks"
import { ErrorBoundary } from "./components/common/ErrorBoundary"
import SubscribeSuccessPage from "./components/pages/SubscribeSuccessPage"
import AppPageNavBar, { appPageLinks } from "./components/nav/AppPageNavBar"
import { VoteBotModal } from "./components/VoteBotModal"
import { getGuildStats } from "./service/analyticsService"
import { getBotInstance } from "./service/botInstanceService"
import TopNotificationBar from "./components/common/TopNotificationBar"
import { getProfile } from "./service/userService"
import { DEFAULT_SETTINGS_TAB } from "./components/pages/MoreSettingsPage"

const HomePage = lazy(() => import("./components/pages/HomePage"))
const GetStartedPage = lazy(() => import("./components/pages/GetStartedPage"))
const CheckoutPage = lazy(() => import("./components/pages/CheckoutPage"))
const DocumentAnswersPage = lazy(() => import("./components/pages/DocumentAnswersPage"))
const FAQsPage = lazy(() => import( "./components/pages/FAQsPage"))
const ConversationalSettingsPage = lazy(() => import("./components/pages/BotSettingsPage"))
const MessageTranslationsPage = lazy(() => import("./components/pages/MessageTranslationsPage"))
const ManageAccessPage = lazy(() => import("./components/pages/ManageAccessPage"))
const MoreSettingsPage = lazy(() => import("./components/pages/MoreSettingsPage"))
const ChannelConfigs = lazy(() => import("./components/pages/ChannelConfigsPage"))
const CustomBotPage = lazy(() => import("./components/pages/CustomBotPage"))
const WebSearchPage = lazy(() => import("./components/pages/WebSearchPage"))
const GuildError = lazy(() => import("./components/common/GuildError"))
const OptimizerPage = lazy(() => import("./components/pages/OptimizerPage"))
const AddonsPage = lazy(() => import("./components/pages/AddonsPage"))
const TutorialModal = lazy(() => import("./components/common/TutorialModal"))
const TermsPrivacyAcceptanceModal = lazy(() => import("./components/TermsPrivacyAcceptanceModal"))

const LOCAL_STORAGE_GUILD_KEY = "guild_id"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#0A0D14',
        }
      }
    }
  }
})

function App() {

  const buildContext = () => {
    const ctx = defaultAppContext
    ctx.setGuild = guild => setAppContext(prev => ({ ...prev, guild }))
    ctx.setFAQs = faqs => setAppContext(prev => ({ ...prev, faqs }))
    ctx.setConfig = config => setAppContext(prev => ({ ...prev, config }))
    ctx.setPlan = plan => setAppContext(prev => ({ ...prev, plan }))
    ctx.setBilling = billing => setAppContext(prev => ({ ...prev, billing }))
    ctx.setUser = user => setAppContext(prev => ({ ...prev, user }))
    ctx.setGuildAnalytics = guildAnalytics => setAppContext(prev => ({ ...prev, guildAnalytics }))
    ctx.setTokenData = currentUsages => setAppContext(prev => ({ ...prev, tokenData: currentUsages }))
    ctx.setServerData = data => setAppContext(prev => ({ ...prev, serverData: data }))
    ctx.setCustomBot = bot => setAppContext(prev => ({ ...prev, customBot: bot }))
    ctx.reloadConfig = () => {
      // hacky but works
      const guildId = new URLSearchParams(window.location.search).get("guild")

      if (guildId) {
        getServerData(guildId)
          .then(data => {
            ctx.setConfig(data.config)
            ctx.setServerData(data)
            console.log(data, "reload success")
            // currently this does not reload FAQs
          })
      }
    }
    return ctx
  }

  const [appContext, setAppContext] = useState<AppContextInterface>(() => buildContext())

  const { guild } = appContext

  const {
    loading,
    data,
    error,
  } = useRequest<ServerDataResponse>(() => guild?.id ? getServerData(guild?.id) : undefined, { depends: [guild] })

  const { isMobile } = useViewport()
  const [searchParams, updateSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const initialGuild = getInitialGuild(searchParams)

  const showTutorial = searchParams.get("showTutorial") === "true"

  useEffect(() => {
    if (data) {
      const faqs = data.faqs || []
      appContext.setFAQs(error || faqs)
      appContext.setConfig(data.config)
      appContext.setPlan(data.plan)
      appContext.setBilling(data.billing)
      appContext.setTokenData(data.token_data)
      appContext.setServerData(data)

      getGuildStats(guild.id).then(stats => {
        appContext.setGuildAnalytics(stats)
      })
      getBotInstance(guild.id).then(botInstance => {
        appContext.setCustomBot(botInstance)
      })

      if (
        data.meta?.show_get_started && !showTutorial && searchParams.get("skipGetStarted") !== "true"
        && !searchParams.get("priceId")
      ) {
        navigate("/get-started")
      }
    }
  }, [data, error])

  useEffect(() => {
    if (guild?.id) {
      localStorage.setItem(LOCAL_STORAGE_GUILD_KEY, guild?.id)
      updateSearchParams({ guild: guild.id })
      document.cookie = `guild_id=${guild.id}; domain=.wallubot.com; path=/; secure; samesite=strict`
      document.cookie = `guild_name=${guild.name}; domain=.wallubot.com; path=/; secure; samesite=strict`
    }
  }, [guild])

  const [openVoteModal, setOpenVoteModal] = useState(false)

  const pageTitle = Object.entries(appPageLinks)
    .map(([_categoryName, category]) => Object.entries(category))
    .flat()
    .find(([key, value]) => value.to === window.location.pathname)?.[0] ?? `Wallu - ${guild?.name || ""}`

  const isLoaded = !!data

  const hideNavEtc = window.location.pathname.startsWith("/get-started") // good enough
  const hasAccess = guild?.is_wallu_access || hideNavEtc

  return (
    <AppContext.Provider value={appContext}>
      <ThemeProvider theme={darkTheme}>
        <div className="flex items-stretch min-h-[100vh]">
          <div>
            {!hideNavEtc && <AppPageNavBar/>}
          </div>
          <div className="w-full">
            {!hideNavEtc && <TopNotificationBar serverData={data}/>}
            <div className={(isMobile ? "mt-20" : "mt-12") + " w-[95%] md:w-4/5 m-auto text-center"}>
              <div className={hideNavEtc ? "hidden" : ""}>
                <div className="flex w-full flex-wrap">
                  <div className="w-full md:w-fit">
                    <Typography
                      color="primary"
                      className="text-left text-blue"
                      style={{ fontSize: isMobile ? "1.25rem" : '2rem' }}
                    >
                      <span>{pageTitle}</span>
                    </Typography>
                    <Divider/>
                  </div>

                  <div className="mx-auto mt-4 lg:mt-0 static right-0 md:mr-[20%]">
                    <UsefulLinks setOpenVoteModal={setOpenVoteModal}/>
                  </div>

                </div>
                <div className="text-end mt-4 ml-[80%] lg:ml-[100%]">
                  {!isMobile && <AccountMenu/>}
                </div>

                <Paper className="mt-8 mb-16 p-4 md:w-3/5">
                  <GuildSelector initialGuild={initialGuild}/>
                </Paper>

                <div className="mb-6">
                  {loading && <LinearProgress/>}
                </div>
              </div>

              {!!guild?.id && <VoteBotModal open={openVoteModal} setOpen={setOpenVoteModal}/>}

              {(isLoaded && !hasAccess) ? (
                <div className="text-xl text-red-500 mt-4">
                  Sorry, you do not have access to this server's panel.
                  <br/>
                  <span className="text-base text-gray-500">
                Please contact the server administrators to get access.
                  </span>
                </div>
              ) : error ? (
                <GuildError error={error}/>
              ) : (
                <Suspense
                  fallback={<p className="text-gray-500">Loading...</p>}
                >
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/get-started" element={<GetStartedPage/>}/>
                      <Route path="/checkout" element={<CheckoutPage/>}/>
                      <Route path="/subscribe-success" element={<SubscribeSuccessPage/>}/>
                      <Route path="/answer-documents" element={<DocumentAnswersPage/>}/>
                      <Route path="/web-search" element={<WebSearchPage/>}/>
                      <Route path="/faqs" element={<FAQsPage loading={loading} setOpenVoteModal={setOpenVoteModal}/>}/>
                      <Route path="/settings" element={<ConversationalSettingsPage/>}/>
                      {/* Redirect the old path to the new /settings. To be removed after a few months or something */}
                      <Route path="/conversational-bot-settings" element={<Navigate to="/settings"/>}/>
                      <Route path="/edit-translations" element={<Navigate to="/messages"/>}/>
                      <Route path="/messages" element={<MessageTranslationsPage/>}/>
                      <Route path="/manage-access" element={<ManageAccessPage/>}/>
                      <Route path="/channels" element={<ChannelConfigs/>}/>
                      <Route path="/optimizer" element={<OptimizerPage/>}/>
                      <Route path="/custom-bot" element={<CustomBotPage/>}/>
                      <Route path="/addons" element={<AddonsPage/>}/>
                      <Route path="/other-settings" element={<Navigate to={"/other-settings/" + DEFAULT_SETTINGS_TAB}/>}/>
                      <Route path="/other-settings/:settingsTab?" element={<MoreSettingsPage/>}/>
                      <Route path="/" element={<HomePage loading={loading}/>}/>
                      <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                    {showTutorial && <TutorialModal/>}
                    {appContext.user && !appContext.user.wallu_user.terms_and_privacy_accepted && (
                      <TermsPrivacyAcceptanceModal
                        onClose={() => {
                          // reload the user -> closes modal
                          getProfile().then(data => appContext.setUser(data))
                        }}
                      />
                    )}
                  </ErrorBoundary>
                </Suspense>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export const getInitialGuild = (searchParams: URLSearchParams): string | undefined => {
  const paramGuild = searchParams.get("guild") as string
  if (paramGuild) {
    // Set initial guild from params, so we will select the correct guild if none was set in local storage yet
    if (!localStorage.getItem(LOCAL_STORAGE_GUILD_KEY)) {
      localStorage.setItem(LOCAL_STORAGE_GUILD_KEY, paramGuild)
      document.cookie = `guild_id=${paramGuild}; domain=.wallubot.com; path=/; secure; samesite=strict`
    }
    return paramGuild
  }
  return localStorage.getItem(LOCAL_STORAGE_GUILD_KEY) || undefined
}

export default App
