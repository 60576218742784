import React from "react"
import { useConfigSettings } from "../../util/hooks/useConfigSettings"
import { FormControlLabel, Slider } from "@mui/material"
import { Controller } from "react-hook-form"
import SaveButton from "../common/SaveButton"

export const DataStorage = () => {

  const { control, isDirty, isValid, handleSubmit } = useConfigSettings()

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold">Your Server Data</h2>
      <p className="max-w-[500px] m-auto text-sm">
        How many days can message contents be stored for?
        Wallu stores some message content for the history search feature and may store questions it has answered or considered to answer in
        order
        to improve its answer's accuracy on your server. See our privacy policy.
        <br/>
        <br/>
        This does not affect channels you have added as documents.
      </p>
      <div className="w-full">
        <Controller
          name="keep_message_content_days"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              label={
                <div>
                  After how many days message contents is deleted forever:
                  <br/>
                  <span className="text-xs">
                    The bot will not be able to learn from messages or votes (👍👎) older than this or use them to answer.
                  </span>
                  <br/>
                  <span className="text-xs">
                    Set to 0 to disable saving message content unless you have added the channel as a document.
                  </span>
                </div>
              }
              className="flex flex-col-reverse my-8"
              control={(
                <Slider
                  {...field}
                  step={1}
                  min={0}
                  valueLabelDisplay="auto"
                  max={365}
                  // marks every 0-30-60-90 etc
                  marks={Array.from(Array(13).keys()).map(i => ({ value: i * 60, label: (i * 60) + " days" }))}
                />
              )}
            />
          )}
        />
      </div>
      <div>
        <SaveButton
          disabled={!isDirty || !isValid}
          onClick={handleSubmit}
        />
      </div>

    </div>
  )
}
