import client, { BASE_URL } from "../config/axios"


export function exportServerDataUrl(guildId: string) {
  return BASE_URL + "/export-server-data/" + guildId
}

export async function importServerData(guildId: string, file: File) {
  // read the file as json
  const data = JSON.parse(await file.text())
  await client.post("/import-server-data/" + guildId, data)
}
