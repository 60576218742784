import Guild from "../model/Guild"
import { FAQ } from "../model/FAQ"
import React from "react"
import { Config } from "../model/Config"
import User from "../model/User"
import { ServerPlan } from "../model/ServerPlan"
import { TokenUsageData } from "../model/TokenUsageData"
import { GuildAnalytics } from "../service/analyticsService"
import { BotInstance } from "../service/botInstanceService"
import { Billing } from "../model/Billing"
import { ServerDataResponse } from "../service/faqService"

export type WalluUser = {
  terms_and_privacy_accepted: boolean
}

type UserData = {
  user: User,
  wallu_user: WalluUser
}

export interface AppContextInterface {
  guild: Guild | undefined
  faqs: FAQ[]
  config: Config | undefined
  plan: ServerPlan | undefined
  billing: Billing | undefined
  user: UserData | undefined
  tokenData: TokenUsageData | undefined
  serverData: ServerDataResponse | undefined
  guildAnalytics: GuildAnalytics | undefined
  setGuildAnalytics: (guildAnalytics: GuildAnalytics) => any
  setConfig: (config: Config) => any
  setPlan: (plan: ServerPlan) => any
  setBilling: (billing: Billing) => any
  setGuild: (guild: Guild) => any
  setFAQs: (faqs: FAQ[]) => any
  setTokenData: (usages: TokenUsageData) => any
  setServerData: (data: ServerDataResponse) => any
  setUser: (userData: UserData) => any
  reloadConfig: () => any
  customBot: BotInstance | undefined
  setCustomBot: (bot: BotInstance) => any
}


export const defaultAppContext = {
  guild: undefined,
  faqs: [],
  config: undefined,
  plan: undefined,
  billing: undefined,
  user: undefined,
  tokenData: undefined,
  serverData: undefined,
  guildAnalytics: undefined,
  customBot: undefined,
  setConfig: (config: Config) => {
  },
  setPlan: (plan: ServerPlan) => {
  },
  setBilling: (billing: Billing) => {
  },
  setGuild: (guild: Guild) => {
  },
  setFAQs: (faqs: FAQ[]) => {
  },
  setUser: (user: User) => {
  },
  setTokenData: (tokenData: TokenUsageData) => {
  },
  setServerData: (data: ServerDataResponse) => {
  },
  reloadConfig: () => {
  },
  setGuildAnalytics: (guildAnalytics: GuildAnalytics) => {
  },
  setCustomBot: (bot: BotInstance) => {
  },

}

export default React.createContext<AppContextInterface>(defaultAppContext)
