import React from "react"

interface PrivacyPolicyDisclaimerProps {
  title: string
  openAI?: boolean
}

export const PrivacyPolicyTermsDisclaimer = ({ title, openAI }: PrivacyPolicyDisclaimerProps) => {

  return (
    <p className="text-gray-300 max-w-[80%] my-8 m-auto">
      <span className="text-xs text-gray-400">
        {title}
        <br/>
          Check our privacy policy for more information.
        {openAI && (
          <>
            &nbsp;Check OpenAI's privacy policy for more.
            <br/>
              We (and OpenAI's API) prohibit the use of these services for any illegal or unethical purposes.
            <br/>
              Read more: <a href="https://openai.com" target="_blank" rel="noreferrer">openai.com</a>, <a href="https://wallubot.com/terms" target="_blank" rel="noreferrer">wallubot.com/terms</a>
          </>
        )}
      </span>
    </p>
  )
}
