import { ServerPlan } from "../../model/ServerPlan"
import { PLANS_WEBSITE_URL } from "../../config/urls"

const faqLimitPopup = (plan: ServerPlan) => {
  if (window.confirm(`Unfortunately, your plan is limited to ${plan.limits.faqs} FAQs.\nWould you like to check our other plans?`)) {
    window.open(PLANS_WEBSITE_URL, '_blank')
  }
}

export default faqLimitPopup
