import { Slider, Tooltip, Typography } from "@mui/material"
import HelpIcon from "@mui/icons-material/Help"
import React from "react"

const ConfidenceSlider = ({ field, ...props }: any) => {
  return (
    <div className="max-w-[400px]" {...props}>
      <Typography gutterBottom className="text-left">
        Minimum confidence
        <Tooltip
          title={
            <span className="text-base">
              How confident should Wallu be before answering the question?
              <br/>
              <br/>
              <u>
                <b>Recommended: 0.7-0.9</b>
              </u>
              <br/>
              <br/>
              Lower values will make the chatbot more likely to answer (more aggressive), but also more likely to answer incorrectly.
            </span>
          }>
          <span> <HelpIcon/></span>
        </Tooltip>
        <br/>
      </Typography>
      <Slider
        {...field}
        step={0.01}
        min={0.5}
        valueLabelDisplay="auto"
        max={1}
        marks={[{ value: 0.5, label: "50%" }, { value: 1, "label": "100%" }]}
      />
    </div>
  )
}

export default ConfidenceSlider
