import { PRIVACY_URL, SUPPORT_URL, TERMS_URL } from "../../config/urls"
import React from "react"
import { DiscordLogo } from "../UsefulLinks"
import { Link } from "@mui/material"

export default function Footer() {

  return (
    <footer className="bg-base pb-4">
      <div className="text-white m-auto">
        <div className="mb-4 w-fit m-auto">
          <Link href={SUPPORT_URL} className="flex" style={{ textDecoration: 'none' }} target="_blank">
            <DiscordLogo/>
            <span className="ml-1 text-gray-400">Support</span>
          </Link>
        </div>
        <div className="mb-6">
          <a href={TERMS_URL} className="text-gray-400 text-sm mr-6" target="_blank" rel="noreferrer">Terms</a>
          <a href={PRIVACY_URL} className="text-gray-400 text-sm" target="_blank" rel="noreferrer">Privacy</a>
        </div>
        <div className="text-gray-400">
          <span className="font-bold">wallubot.com</span>
          <br/>
          &copy; {new Date().getFullYear()} Copyright toppev.
          All Rights Reserved.
        </div>
      </div>
    </footer>

  )
}
