import React from "react"
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { saveConfig } from "../../service/faqService"
import useAppContext from "../../context/useAppContext"
import { PrivacyPolicyTermsDisclaimer } from "../common/PrivacyPolicyTermsDisclaimer"

export default function HistorySearchSettings() {

  const context = useAppContext()
  const guildId = context.guild?.id

  const update = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(e.target.value)
    await saveConfig(guildId!!, { ...context.config, semantic_search_threshold: val })
      .then(() => {
        // @ts-ignore
        context.setConfig({
          ...context.config,
          semantic_search_threshold: val,
        })
      }).catch(err => {
        window.alert(`Error saving, please try again\n(${err})`)
      })
  }

  if (!context.config) {
    return null
  }

  return (
    <div>
      <h1 className="text-2xl my-2">Answer from history</h1>
      <p className="text-gray-300 max-w-[600px] m-auto">
        Your members will often ask questions that have already been answered in the recent past but not (yet) added in FAQs.
        This feature allows Wallu to save and search through the history of enabled channels to find the answer to your members' questions.
      </p>
      <FormControl className="w-full">
        <FormLabel id="history-search-selector" className="mt-8">
          Enable smart history search for similar questions
        </FormLabel>
        <RadioGroup
          className="ml-[25%] my-6"
          aria-labelledby="history-search-selector"
          defaultValue={context.config?.semantic_search_threshold}
          onChange={update}
        >
          <FormControlLabel
            value="0.99"
            control={<Radio/>}
            label={
              <span>Search only when Wallu is tagged</span>
            }
          />
          <FormControlLabel
            value="0.5"
            control={<Radio/>}
            label={
              <span>Let our AI decide when to search (default)</span>
            }
          />
          <FormControlLabel
            value="0"
            control={<Radio/>}
            label={
              <span>Disable search and storing messages</span>
            }
          />
        </RadioGroup>
      </FormControl>

      <PrivacyPolicyTermsDisclaimer
        title="Only messages in enabled channels that seem like questions (or their answers) are stored with their content encrypted and deleted after 30 days (unless you decide on a longer period)."
      />
    </div>
  )
}
