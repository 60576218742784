import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

// Extend mui button
const SaveButton = (props: ButtonProps) => {
  const { children, ...rest } = props
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<SaveIcon/>}
      {...rest}
    >
      {children || "Save"}
    </Button>
  )

}


export default SaveButton
