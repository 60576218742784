import React, { useCallback, useEffect, useState } from "react"
import useAppContext from "../../context/useAppContext"
import { Button, debounce, TextareaAutosize } from "@mui/material"
import CreditsUsedBar from "../common/CreditUsageBar"
import { estimateImport, extractFAQs, ImportedFAQ, pollExtractedFAQs, TaskPollResponse, upsertFAQs } from "../../service/faqService"
import FAQTableImporterView from "../FAQTableImporterView"
import { FAQ } from "../../model/FAQ"
import TaskProgress from "../common/TaskProgress"
import { PrivacyPolicyTermsDisclaimer } from "../common/PrivacyPolicyTermsDisclaimer"

export const ExtractFAQs = () => {

  const { faqs, setFAQs, reloadConfig, guild, plan, serverData } = useAppContext()

  const [importProgress, setImportProgress] = useState<TaskPollResponse | undefined>()
  const [content, setContent] = useState("")
  const [extractedFAQs, setExtractedFAQs] = useState<ImportedFAQ[]>([])
  const [estimatedTokenCost, setEstimatedTokenCost] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    reloadConfig()
    const interval = setInterval(() => {
      reloadConfig()
    }, 5_000)
    return () => clearTimeout(interval)
  }, [importProgress])

  const updateTokenEstimate = useCallback(debounce((content: string) => {
    estimateImport(content).then(data => {
      setEstimatedTokenCost(data.estimated_token_usage)
    })
  }, 500), [])

  useEffect(() => {
    if (content?.length > 10) {
      updateTokenEstimate(content)
    }
  }, [content])


  const minLength = 200
  const maxLength = 100_000
  const isValid = content.length <= maxLength && content.length >= minLength
  const isLoading = !!importProgress && !importProgress.ready

  const processImport = async () => {
    setImportProgress({ current: 0, total: 1, state: 'PENDING', ready: false })
    try {
      const task = await extractFAQs(guild.id, content)

      const interval = setInterval(async () => {
        const taskId = task.task_id
        const result = await pollExtractedFAQs(taskId)
        setImportProgress(result)
        if (result.state === "SUCCESS") {
          const importedFAQS = result.result || []
          setExtractedFAQs(importedFAQS)
          setImportProgress(undefined)
          setDialogOpen(true)
          if (importedFAQS.length === 0) {
            window.alert("Unfortunately, we couldn't create any FAQs from the text you provided. Please try again with a different or longer text/documentation.")
          }
        }
        if (["SUCCESS", "FAILURE"].includes(result.state)) {
          clearInterval(interval)
        }
      }, 5_000)
    } catch (err) {
      console.error(err)
      window.alert("Error occurred: " + err)
    }
  }
  return (
    <div className="mb-12">
      {extractedFAQs?.length > 0 && (
        <FAQTableImporterView open={dialogOpen} setOpen={setDialogOpen} faqs={extractedFAQs} importFAQs={async newFAQs => {
          // @ts-ignore
          const allFaqs: FAQ[] = [...faqs, ...newFAQs]
          await upsertFAQs(guild.id, allFaqs).then(() => {
            setFAQs(allFaqs)
          })
          reloadConfig()
        }}
        />
      )}
      <div>
        <h2 className="text-2xl font-bold">Import FAQs</h2>
        <p className="text-sm text-gray-300 my-6">
          Automagically import FAQs from your documentation.
          <br/>
          Currently you can only paste the content here, but in the future you will be able to import from a URL or a file.
        </p>

        <TextareaAutosize
          disabled={isLoading}
          className="bg-[#1B1F27] p-4"
          minRows={20}
          maxRows={20}
          placeholder="Paste your documentation here. For example, you can copy the CONTENT from your current FAQs, website, documentation (e.g., README), or a Google Doc. Wallu does not read linked websites (e.g., wiki links)!"
          style={{ width: "100%" }}
          value={content}
          onChange={e => setContent(e.target.value)}
        />
        <p className="text-gray-200 text-xs text-right">{content?.length}/{maxLength}</p>

        {importProgress && (
          <div>
            <TaskProgress
              message="This may take a few minutes. Please do not close this page."
              current={importProgress?.current || 0}
              total={importProgress?.total || 1}
              status={importProgress?.state || 'PENDING'}
              error={importProgress?.error}
            />
          </div>
        )}

        <Button
          variant="contained"
          color="primary"
          className="mt-2"
          disabled={!content || !isValid || isLoading}
          onClick={processImport}>
          Import
        </Button>

        {!isValid && (
          <div className="text-red-500 mt-2">
            Content must be between {minLength} and {maxLength} characters.
          </div>
        )}

        <div className="text-red-500 mt-2">
          Note: you're limited to {plan?.limits?.faqs} FAQs (currently you have {faqs.length}).
        </div>

        <PrivacyPolicyTermsDisclaimer
          title="The content provided will be used with OpenAI's API. Check their privacy policy for more."
        />

        <div className="my-6 w-2/3 m-auto text-sm">
          <p className="text-gray-300 my-2">
            Importing FAQs will use some of your credits.
            <br/>
            {content?.length > 0 && (
              <span>Estimated to use ~{Math.round(estimatedTokenCost / 100) * 100} tokens</span>
            )}
          </p>

          <CreditsUsedBar
            usageText="monthly credits used"
          />

        </div>

      </div>
    </div>
  )
}
