import React from "react"
import SideNavBar from "./SideNavBar"

import HomeIcon from '@mui/icons-material/Home'
import TuneIcon from '@mui/icons-material/Tune'
import ChatIcon from '@mui/icons-material/Chat'
import DescriptionIcon from '@mui/icons-material/Description'
import TranslateIcon from '@mui/icons-material/Translate'
import SecurityIcon from '@mui/icons-material/Security'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import CustomBotIcon from '@mui/icons-material/FaceRetouchingNatural'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import HelpIcon from '@mui/icons-material/Help'
import BuildIcon from '@mui/icons-material/Build'
import ApiIcon from '@mui/icons-material/Api'
import WebSearchIcon from '@mui/icons-material/TravelExplore'
import { API_SPEC_URL, DOCUMENTATION_URL, GET_STARTED_TUTORIAL, PRIVACY_URL, SUPPORT_URL, TERMS_URL } from "../../config/urls"

export const appPageLinks = {
  "": {
    "Panel Home": {
      to: "/",
      icon: <HomeIcon/>,
    },
  },
  "Knowledge Bases": {
    "Your FAQs": {
      to: "/faqs",
      icon: <QuestionAnswerIcon/>,
    },
    "Your Documents": {
      to: "/answer-documents",
      icon: <DescriptionIcon/>,
    },
    "(Beta) Web Search": {
      to: "/web-search",
      icon: <WebSearchIcon/>,
    },
  },
  "Customize & Configure": {
    "Bot Settings": {
      to: "/settings",
      icon: <SmartToyIcon/>,
    },
    "Channel Settings": {
      to: "/channels",
      icon: <ChatIcon/>,
    },
    "Customize Bot": {
      to: "/custom-bot",
      icon: <CustomBotIcon/>,
    },
    "Messages": {
      to: "/messages",
      icon: <TranslateIcon/>,
    },
  },
  "Advanced": {
    "Optimizer": {
      to: "/optimizer",
      icon: <TuneIcon/>,
    },
    "Manage Access": {
      to: "/manage-access",
      icon: <SecurityIcon/>,
      // @ts-ignore
      disabled: ctx => !ctx.guild?.is_admin,
      title: "Only members with Administrator Discord permission can edit these settings.",
    },
  },
  "Other": {
    "Other Tools & Settings": {
      to: "/other-settings",
      icon: <BuildIcon/>,
    },
    "Addons & API Keys": {
      to: "/addons",
      icon: <ApiIcon/>,
    },
    "Help & Support": {
      to: "https://wallubot.com/support",
      icon: <HelpIcon/>,
    },
  },
} as const

export const miniLinks = {
  "Get Started - Tutorial": GET_STARTED_TUTORIAL,
  "Support": SUPPORT_URL,
  "Privacy Policy": PRIVACY_URL,
  "Terms of Service": TERMS_URL,
  "Documentation": DOCUMENTATION_URL,
  "Developer API": API_SPEC_URL,
} as const

const AppPageNavBar = () => {

  return (
    <SideNavBar links={appPageLinks} miniLinks={miniLinks} ctaEnabled={false}/>
  )
}

export default AppPageNavBar
