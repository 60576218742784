import React from "react"
import Footer from "../partials/Footer"

interface PanelPageProps extends React.PropsWithChildren<any> {
}

function PanelPage({ children }: PanelPageProps) {
  return (
    <div className="flex-1">
      <div className="my-[5%] mb-[10vh] min-h-[90vh]">
        {children}
      </div>
      <Footer/>
    </div>
  )
}

export default PanelPage
