import React from "react"
import { Button, Divider } from "@mui/material"
import { exportServerDataUrl, importServerData } from "../../service/exportImportService"
import useAppContext from "../../context/useAppContext"

export const ExportImport = () => {

  const { guild } = useAppContext()

  if (!guild) return null

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold">Export & Import Server data</h2>
      <div className="m-auto text-md max-w-[700px] my-2">
        Export includes the following data: FAQ, Config settings, Documents, Custom bot configuration (excluding bot token)
        <br/>
        <br/>
        You can use this feature as a backup or to transfer data to another server.
        Note that some data, such as channels (enabled channels etc.) will not work properly as they use channel IDs and require manual configuration after
        import to another server.
      </div>
      <div className="my-4 flex gap-4 flex-col w-fit m-auto">
        <Button
          variant="contained"
          color="primary"
          href={exportServerDataUrl(guild.id)}
          target="_blank"
        >
          Export this server's data
        </Button>
        <Divider/>
        <h3 className="mt-4 text-lg font-bold">Import server data</h3>
        <input
          type="file"
          accept=".json"
          onChange={async e => {
            if (e.target.files && e.target.files[0]) {
              if (window.confirm("This will overwrite the server's data. Are you sure?")) {
                if (window.confirm("Are you really sure? You may lose FAQs, docs and settings if this fails.")) {
                  try {
                    await importServerData(guild.id, e.target.files[0])
                    alert("Import was successful!")
                    window.location.reload()
                  } catch (err) {
                    alert("Import failed: " + err.message)
                  }
                }
              }
            }
            e.target.value = ""
          }}
        />
      </div>

    </div>
  )
}
