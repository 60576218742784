import { saveConfig } from "../../service/faqService"
import { useForm, useFormState } from "react-hook-form"
import useAppContext from "../../context/useAppContext"
import { useObjectChangeEffect } from "./useObjectChangeEffect"

export function useConfigSettings() {

  const { guild, config, setConfig } = useAppContext()

  const saveConfigValues = async (values: any) => {
    const newConfig = { ...config, ...values }
    await saveConfig(guild?.id!!, newConfig)
      .then(() => {
        // @ts-ignore
        setConfig(newConfig)
      })
      .catch(err => {
        console.error(err)
        const message = err.response?.data?.message || `Failed to save settings: ${err}`
        window.alert(message)
      })
    return newConfig
  }

  const { handleSubmit, control, reset, getValues, setValue, watch } = useForm<any>({
    defaultValues: {
      ...config
    },
    mode: 'onChange',
  })
  const { isDirty, isValid } = useFormState({ control })

  const submit = async (values: any) => {
    await saveConfigValues(values)
    reset(values)
  }

  useObjectChangeEffect(() => {
    if (config) reset(config)
  }, [config])

  return {
    control,
    isDirty,
    isValid,
    getValues,
    setValue,
    reset,
    watch,
    manualSaveConfigValues: saveConfigValues,
    handleSubmit: handleSubmit(submit),
    isLoaded: !!config && !!guild,
  }
}
